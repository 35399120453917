<template>
  <div class="d-flex flex-column">
    <v-btn
      v-if="editable && userHasRightsToEditCourse"
      color="primary"
      text
      class="my-2 mr-auto ml-2"
      @click="openRideEditDialog"
    >
      <v-icon left>mdi-pencil</v-icon>
      EDYTUJ RELACJĘ
    </v-btn>
    <v-card-text class="flex-grow-1" v-if="isRideSegmentsVisible">
      <div v-for="(segment, index) in segments" :key="index">
        <template>
          <div
            :class="segment.achievedAt ? 'success-segment row no-gutters flex-nowrap' : 'row no-gutters flex-nowrap'"
            style="column-gap: 15px"
          >
            <div class="col-1 d-flex align-center justify-center">
              <v-icon small>{{ segment.icon }}</v-icon>
            </div>
            <div class="pl-0 d-flex flex-grow-1 justify-space-between">
              <div>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-chip
                      class="black--text mr-2 text-body-2 pa-2 rounded-pill"
                      v-on="on"
                      v-bind="attrs"
                      style="height: 20px"
                      x-small
                    >
                      {{ segment.waypointTime }}
                    </v-chip>
                  </template>
                  <span>Zaplanowany dojazd o {{ segment.fullTime | datetimeHourFirst }}</span>
                </v-tooltip>
                <span class="black--text text-body-1">{{ getPlaceName(segment) }}</span>
                <p v-if="isSegmentFullAddressVisible" class="mb-0">{{ getPlaceAddress(segment) }}</p>
              </div>
              <div
                v-if="courseInfo && courseStarted && ride.status !== 'declined_by_driver'"
                class="d-flex justify-center align-center"
                style="min-width: 36px"
              >
                <v-btn v-if="!segment.achievedAt" icon :aria-label="`Osiągnięto przystanek ${index + 1}`">
                  <v-icon color="grey lighten-1" @click="selectedWaypoint = segment">mdi-check-circle-outline</v-icon>
                </v-btn>
                <v-icon v-else color="#4caf50">mdi-check-circle</v-icon>
              </div>
              <div v-else-if="!courseInfo">
                <v-icon v-if="!segment.achievedAt" color="grey lighten-1">mdi-check-circle-outline</v-icon>
                <v-tooltip top v-else>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-on="on" v-bind="attrs" color="#4caf50">mdi-check-circle</v-icon>
                  </template>
                  <span>Dojazd o {{ segment.achievedAt | datetimeHourFirst }}</span>
                </v-tooltip>
              </div>
            </div>
          </div>
          <div
            v-if="index !== segments.length - 1"
            class="row no-gutters flex-nowrap mt-0"
            style="min-height: 45px; column-gap: 15px"
          >
            <div class="col-1 d-flex align-center justify-center pa-0">
              <v-divider
                v-if="isIntermediateWaypointsVisible || isTwoWaypointRide"
                class="rounded"
                style="height: 16px"
                vertical
              />
              <collapsed-waypoints-indicator v-else :count="numberOfHiddenWaypoints" />
            </div>
            <div v-if="isSegmentInfoVisible" class="my-6 d-flex flex-column justify-center" style="row-gap: 4px">
              <div
                v-if="
                  isPassengersVisible &&
                  (segment.employees.length ||
                    (!isIntermediateWaypointsVisible && getEmployeesFromAllSegments().length))
                "
                class="d-flex"
              >
                <v-icon small>mdi-hail</v-icon>
                <div class="d-flex flex-wrap ml-2">
                  <span
                    class="segments-timeline__employee-name"
                    v-for="employee in getEmployees(segment)"
                    :key="employee.id"
                  >
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <span>{{ employee.name }}</span>
                        <a
                          :href="`tel:${employee.phone}`"
                          @click.stop
                          v-bind="attrs"
                          v-on="on"
                          class="text-decoration-none"
                        >
                          <v-icon small color="primary">mdi-phone</v-icon>
                        </a>
                      </template>
                      <span>
                        {{ employee.phone | phone }}
                      </span>
                    </v-tooltip>
                  </span>
                </div>
              </div>
              <div v-if="isSegmentDistanceAndTimeVisible">
                <v-icon small>mdi-transit-connection-variant</v-icon>
                <span class="ml-2">
                  {{ distanceLabel(segment) }} km ({{ durationLabel(segment) | secondsToHumanString }})
                </span>
              </div>
              <div
                v-if="
                  isSegmentLocomotivesVisible &&
                  ((segment.locomotives && segment.locomotives.length) ||
                    (!isIntermediateWaypointsVisible && locomotivesFromRide.length))
                "
              >
                <v-icon small>mdi-train</v-icon>
                <span class="ml-2">{{ locomotivesLabel(segment) }}</span>
              </div>
            </div>
          </div>
        </template>
      </div>
    </v-card-text>
    <confirm-dialog
      v-if="courseInfo"
      :show-dialog="!!selectedWaypoint"
      :text="`Osiągnięto przystanek ${getPlaceName(selectedWaypoint)}?`"
      cancel-text="jeszcze nie"
      description="Automatycznie poinformujesz pasażerów oraz dyspozytora o dotarciu na ten przystanek."
      @confirm="confirmDialog"
    />
    <confirm-dialog
      data-testid="decline-ride-modal"
      :show-dialog="showRideDeclineDialog"
      text="Odmowa wykonania kursu"
      cancel-text="anuluj"
      confirm-text="odrzuć"
      description="Czy na pewno chcesz odrzucić kurs?"
      @confirm="declineRideConfirmDialog"
    />
    <!-- segments-edit-dialog -->
    <v-dialog v-model="rideEditDialog" fullscreen hide-overlay transition="dialog-bottom-transition">
      <v-card>
        <v-toolbar elevation="0">
          <v-btn icon @click="closeRideEditDialog">
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
          <v-toolbar-title>Edycja relacji</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn color="primary" :disabled="isEditRequestPending || isLoading" text @click="saveRelation">
              zapisz relację
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <course-segment-form class="pa-4" :segments="waypointsForEdit" :company-id="getCompanyId" />
      </v-card>
    </v-dialog>
    <!-- starting-base-edit-dialog -->
    <v-dialog v-model="startingBaseEditDialog" max-width="500px">
      <v-card>
        <v-card-title class="text-h5 text-break">Edycja bazy startowej</v-card-title>
        <v-card-text>
          <starting-base-field :value="startingBase" @input="editRideStartingBase" />
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="closeStartingBaseEditDialog">anuluj</v-btn>
          <v-btn color="primary" text :disabled="isEditRequestPending" @click="updateRideStartingBase">
            Zatwierdź zmiany
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- buttons for accepting or declining course -->
    <div v-if="isRideActionsVisible" class="d-flex mx-4 mb-5 col-gap-4">
      <v-btn class="flex-grow-1" color="success" @click.stop="emitAcceptRide" data-testid="accept-ride-btn">
        <v-icon left dark>mdi-check</v-icon>
        akceptuj
      </v-btn>
      <v-btn class="flex-grow-1" color="error" @click.stop="openRideDeclineDialog" data-testid="decline-ride-btn">
        <v-icon left dark>mdi-close</v-icon>
        odrzuć
      </v-btn>
    </div>
    <!-- system bar with starting base info etc. -->
    <v-system-bar
      v-if="!courseInfo"
      class="d-flex justify-space-between align-center py-1 px-4 text-body-1 rounded-b"
      color="#F5F5F5"
      height="auto"
    >
      <div>
        <b>Baza:</b>
        <v-chip
          v-if="editable && userHasRightsToEditStartingBase"
          color="#007dff1f"
          text-color="black"
          small
          class="ml-2"
          data-testid="starting-base-edit"
          @click="openStartingBaseEditDialog"
        >
          {{ firstPlaceName }}
          <v-icon right color="primary">mdi-pencil</v-icon>
        </v-chip>
        <span v-else>{{ firstPlaceName }}</span>
        <v-icon color="#C4C4C4">mdi-circle-medium</v-icon>
        <b>Łącznie:</b>
        <a
          v-if="isRouteUrlDefined"
          class="segments-timeline__link"
          :href="relationRouteUrl"
          target="_blank"
          rel="noopener noreferrer"
          @click.stop
        >
          {{ totalRideDistance }}
        </a>
        <span v-else class="text-decoration-underline">
          {{ totalRideDistance }}
        </span>
      </div>
      <v-tooltip top v-if="ride.createdBy">
        <template v-slot:activator="{ on, attrs }">
          <v-avatar class="segments-timeline__avatar" v-on="on" v-bind="attrs" color="#0000003B" size="20" @click.stop>
            {{ getDispatcherInitials }}
          </v-avatar>
        </template>
        <span>Dyspozytor: {{ ride.createdBy.name }}</span>
      </v-tooltip>
    </v-system-bar>
  </div>
</template>
<script>
import ConfirmDialog from '@/component/Dialog/confirm-dialog';
import TaxiCourseAdapter from '@/core/shared/taxi-course-adapter';
import CourseSegmentForm from '@/component/NewCourse/Form/Ride/Segment/course-segment-form';
import StartingBaseField from '@/component/Field/Place/starting-base-field';
import CollapsedWaypointsIndicator from '@/component/NewCourse/collapsed-waypoints-indicator';
import { TAXI_COURSE_RIDE_STATUS } from '@/core/dict/taxi-course-dict';
import { COURSE_TYPE_DICT } from '@/core/dict/course-dict';
import { TAXI_COURSE_LIST_ROUTE } from '@/core/router/route-names';
import { mapGetters } from 'vuex';
import { uniqueValues } from '@/core/shared/uniqueFilter';
import dayjs from 'dayjs';
import Vue from 'vue';
import TaxiCourseService from '@/service/TaxiCourseService';
import NullEdit from './Segments/EditStartingBaseTemplate/NullEdit';
import DriverSegmentsEdit from './Segments/EditRelationTemplate/DriverSegmentsEdit';
import SegmentsEdit from './Segments/EditRelationTemplate/SegmentsEdit';

export default {
  name: 'SegmentsTimeline',
  components: {
    ConfirmDialog,
    CourseSegmentForm,
    StartingBaseField,
    CollapsedWaypointsIndicator,
  },
  props: {
    ride: Object,
    course: Object,
    extendedView: Boolean,
    editable: Boolean,
    courseInfo: Boolean,
    courseStarted: Boolean,
    startingBaseStrategy: {
      type: Object,
      required: true,
    },
    startingPlaceStrategy: {
      type: Object,
      required: true,
    },
    segmentsEditStrategy: {
      type: Object,
      required: true,
    },
    driverSegmentsEditStrategy: {
      type: Object,
      required: true,
    },
    isDriverRelation: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedWaypoint: false,
      showRideDeclineDialog: false,
      rideEditDialog: false,
      startingBaseEditDialog: false,
      selectedStartingBase: this.ride.fromStartingBase?.waypointStart?.place,
      isEditRequestPending: false,
    };
  },
  computed: {
    ...mapGetters([
      'isDriver',
      'isManager',
      'isTransportCompany',
      'userHasPermissionToEditCourse',
      'user',
      'loggedCompany',
      'isLoggedCompanyIsSuperiorContractor',
    ]),
    ...mapGetters('taxiCourse', ['isLoading']),
    isRouteUrlDefined() {
      return this.isDriverRelation ? !!this.course.routeForDriverRelation : !!this.ride.routeUrl;
    },
    relationRouteUrl() {
      return this.isDriverRelation ? this.course.routeForDriverRelation : this.ride.routeUrl;
    },
    isCourseRide() {
      return this.ride['@type'] === COURSE_TYPE_DICT.TAXI_COURSE_RIDE;
    },
    isTaxiCourse() {
      return this.ride['@type'] === COURSE_TYPE_DICT.TAXI_COURSE;
    },
    isKilometerCourseRide() {
      return this.ride?.['@type'] === COURSE_TYPE_DICT.KILOMETER_COURSE_RIDE;
    },
    isCourseCreatedByCurrentUser() {
      return this.ride.createdBy.id === this.$store.getters.user.id;
    },
    userHasRightsToEditCourse() {
      return this.isCourseRide
        ? this.userHasPermissionToEditCourse
        : this.isCourseCreatedByCurrentUser || this.isDriver;
    },
    userHasRightsToEditStartingBase() {
      if (this.isDriverRelation) {
        return false;
      }

      return this.isManager || this.isLoggedCompanyIsSuperiorContractor({ course: this.course, ride: this.ride });
    },
    waypointsForEdit() {
      return this.$store.getters['courseCreation/course'].rides[0].segments;
    },
    getDispatcherInitials() {
      // TODO: after resolving circular dependency in tests add test case
      return this.ride.createdBy.name
        .split(' ')
        .slice(0, 2)
        .map((word) => word[0].toUpperCase())
        .join('');
    },
    firstPlaceName() {
      return this.ride?.fromStartingBase?.waypointStart.place?.name;
    },
    segments() {
      return this.isIntermediateWaypointsVisible ? this.segmentsWithAllWaypoints : this.segmentWithoutIntermediateStops;
    },
    segmentsWithAllWaypoints() {
      // TODO: find what resets fromStartingBase to null on course with zero segments
      if (this.ride.segments.length === 0) {
        const waypoint = this.ride.fromStartingBase?.waypointEnd;
        return [
          {
            waypointId: waypoint?.id,
            achievedAt: waypoint?.achievedAtByDriver,
            place: waypoint?.place,
            icon: this.getPlaceIcon(waypoint?.place),
            time: waypoint?.time?.split('T')[1].slice(0, 5),
            fullTime: waypoint?.time,
          },
        ];
      }

      const waypoints = this.ride.segments.map((segment) =>
        this.createWaypoint({ segment, waypoint: segment.waypointStart })
      );

      if (!this.ride.id) {
        return waypoints;
      }

      const lastSegment = this.ride.segments.at(-1);
      waypoints.push(this.createWaypoint({ segment: lastSegment, waypoint: lastSegment.waypointEnd }));

      return waypoints;
    },
    segmentWithoutIntermediateStops() {
      if (this.ride.segments.length === 0) {
        const waypoint = this.ride.fromStartingBase?.waypointEnd;
        return [
          {
            waypointId: waypoint?.id,
            achievedAt: waypoint?.achievedAtByDriver,
            place: waypoint?.place,
            icon: this.getPlaceIcon(waypoint?.place),
            time: waypoint?.time?.split('T')[1].slice(0, 5),
            fullTime: waypoint?.time,
          },
        ];
      }

      const firstSegment = this.ride.segments[0];
      const lastSegment = this.ride.segments.at(-1);

      return [
        this.createWaypoint({ segment: firstSegment, waypoint: firstSegment.waypointStart }),
        this.createWaypoint({ segment: lastSegment, waypoint: lastSegment.waypointEnd }),
      ];
    },
    totalSegmentsDistance() {
      return this.ride.segments.reduce((acc, segment) => acc + (segment.distance ?? 0), 0);
    },
    isCourseListRoute() {
      return this.$route.name === TAXI_COURSE_LIST_ROUTE;
    },
    isRideWaitingForTransportCompanyAcceptance() {
      return (
        this.ride.status === TAXI_COURSE_RIDE_STATUS.WAITING_FOR_TRANSPORT_COMPANY_ACCEPTANCE ||
        this.ride.status === TAXI_COURSE_RIDE_STATUS.NOT_ACCEPTED_BY_TRANSPORT_COMPANY
      );
    },
    isLoggedCompanyIsSubcontractor() {
      return this.user.company.id === this.course.subcontractor?.id;
    },
    isLoggedCompanyIsContractor() {
      return this.user.company.id === this.course.contractor?.id && this.course.subcontractor === null;
    },
    // TODO: lack of tests
    isRideActionsVisible() {
      return (
        this.isCourseListRoute &&
        this.isTransportCompany &&
        this.isRideWaitingForTransportCompanyAcceptance &&
        (this.isLoggedCompanyIsSubcontractor || this.isLoggedCompanyIsContractor)
      );
    },
    startingBase() {
      return this.ride.fromStartingBase?.waypointStart?.place;
    },
    getCompanyId() {
      return this.isDriverRelation ? this.course.rides.map((ride) => ride.company.id) : this.ride?.company?.id;
    },
    getCompanies() {
      return this.isDriverRelation ? this.course.rides.map((ride) => ride.company) : this.ride?.company;
    },
    totalRideDistance() {
      return this.isDriverRelation
        ? `${this.course.driverDistanceSum} km`
        : `${this.ride.distanceSum || this.totalSegmentsDistance} km`;
    },
    totalRideDuration() {
      return this.ride.segments.reduce((acc, segment) => acc + segment.time, 0);
    },
    locomotivesFromRide() {
      return this.ride.locomotivesSideNumbers;
    },
    // TODO: eliminate repetition of the same condition -- probably to many responsibilities
    isPassengersVisible() {
      return !this.extendedView ? this.$store.getters['courseSettings/isPassengersVisible'] : true;
    },
    isSegmentDistanceAndTimeVisible() {
      return !this.extendedView ? this.$store.getters['courseSettings/isSegmentDistanceAndTimeVisible'] : true;
    },
    isSegmentLocomotivesVisible() {
      return !this.extendedView ? this.$store.getters['courseSettings/isSegmentLocomotivesVisible'] : true;
    },
    isSegmentFullAddressVisible() {
      return !this.extendedView ? this.$store.getters['courseSettings/isSegmentFullAddressVisible'] : true;
    },
    isIntermediateWaypointsVisible() {
      return !this.extendedView ? this.$store.getters['courseSettings/isIntermediateWaypointsVisible'] : true;
    },
    isRideSegmentsVisible() {
      return !this.extendedView ? this.$store.getters['courseSettings/isRideSegmentsVisible'] : true;
    },
    isSegmentInfoVisible() {
      return this.isSegmentDistanceAndTimeVisible || this.isPassengersVisible || this.isSegmentLocomotivesVisible;
    },
    isTwoWaypointRide() {
      const RIDE_WITHOUT_INTERMEDIATE_WAYPOINTS = 2;
      return this.segmentsWithAllWaypoints.length === RIDE_WITHOUT_INTERMEDIATE_WAYPOINTS;
    },
    numberOfHiddenWaypoints() {
      const BOUNDARY_POINTS = 2;
      return this.segmentsWithAllWaypoints.length - BOUNDARY_POINTS;
    },
    isStartingBaseRelationChanged() {
      return (
        this.ride.fromStartingBase?.waypointEnd.place.id !== this.waypointsForEdit[0].waypointStart.place.id ||
        this.ride.toStartingBase?.waypointStart.place.id !== this.waypointsForEdit.at(-1).waypointStart.place.id
      );
    },
    selectedStartingBaseChanged() {
      return this.selectedStartingBase?.id !== this.ride.fromStartingBase?.waypointStart?.place?.id;
    },
  },
  methods: {
    createWaypoint({ segment, waypoint }) {
      return {
        ...segment,
        waypointId: waypoint.id,
        achievedAt: waypoint.achievedAtByDriver,
        place: waypoint.place,
        icon: this.getPlaceIcon(waypoint.place),
        waypointTime: waypoint?.time?.split('T')[1].slice(0, 5),
        fullTime: waypoint?.time,
      };
    },
    async saveRelation() {
      this.setEditRequestStatusTo(true);

      try {
        await this.editRide();
        await this.editCourse();
        this.closeRideEditDialog();
      } finally {
        this.setEditRequestStatusTo(false);
      }
    },
    async editRide() {
      if (this.segmentsEditStrategy instanceof SegmentsEdit && !this.isKilometerCourseRide) {
        const { waypoints } = await this.$store.dispatch('taxiCourse/updateValues', {
          '@id': `/api/taxi_course_rides/${this.isTaxiCourse ? this.course.rides[0].id : this.ride.id}`,
          payload: await this.getEditedRidePayload(),
        });

        return this.$store.dispatch('courseCreation/updateWaypointsId', waypoints);
      }

      if (this.isCourseRide || this.isTaxiCourse) {
        return Promise.resolve();
      }

      return this.$store.dispatch('taxiCourse/updateValues', {
        '@id': `/api/kilometer_course_rides/${this.ride.id}`,
        payload: await this.getEditedRidePayload(),
      });
    },
    async editCourse() {
      if (this.driverSegmentsEditStrategy instanceof DriverSegmentsEdit) {
        return this.$store.dispatch('taxiCourse/updateValues', {
          '@id': `/api/taxi_courses/${this.ride.courseId || this.ride.id}`,
          payload: {
            ...(await this.driverSegmentsEditStrategy.payload(await this.modifiedDriverSegments())),
            ...(await this.startingPlaceStrategy.payload({
              ride: this.ride,
              selectedStartingBase: this.selectedStartingBase,
              selectedStartingBaseChanged: this.selectedStartingBaseChanged,
            })),
          },
        });
      }
      return Promise.resolve();
    },
    editRideStartingBase(val) {
      this.selectedStartingBase = val;
    },
    async updateRideStartingBase() {
      this.setEditRequestStatusTo(true);

      this.$store
        .dispatch('taxiCourse/updateValues', {
          '@id': `/api/taxi_courses/${this.ride.courseId || this.ride.id}`,
          payload: {
            ...(await this.startingPlaceStrategy.payload({
              ride: this.ride,
              selectedStartingBase: this.selectedStartingBase,
              selectedStartingBaseChanged: this.selectedStartingBaseChanged,
            })),
            ...(await this.startingBaseStrategy.payload({
              course: this.course,
              ride: this.ride,
              selectedStartingBase: this.selectedStartingBase,
              selectedStartingBaseChanged: this.selectedStartingBaseChanged,
            })),
          },
        })
        .finally(() => {
          this.closeStartingBaseEditDialog();
          this.setEditRequestStatusTo(false);
        });
    },
    closeStartingBaseEditDialog() {
      this.startingBaseEditDialog = false;
    },
    closeRideEditDialog() {
      this.rideEditDialog = false;
      this.$store.commit('courseCreation/RESET_COURSE');
    },
    setEditRequestStatusTo(val) {
      this.isEditRequestPending = val;
    },
    async modifiedDriverSegments() {
      if (this.isDriverRelation || this.driverSegmentsEditStrategy instanceof NullEdit) {
        return this.waypointsForEdit;
      }

      const driverSegmentsCopy = this.course.rides
        .filter((ride) => ride.status === 'accepted_by_driver' && ride.id !== this.ride.id)
        .map((ride) => new TaxiCourseAdapter(ride).transformSegmentsToWaypoints())
        .flat();

      driverSegmentsCopy.push(...this.waypointsForEdit);
      driverSegmentsCopy.sort((a, b) => new Date(a.waypointStart.time) - new Date(b.waypointStart.time));

      driverSegmentsCopy.forEach((waypoint, index) => {
        // eslint-disable-next-line no-param-reassign
        waypoint = {
          ...waypoint,
          distance: null,
          time: null,
          estimatedDistance: null,
          definedDistance: null,
          fallbackDistance: null,
          estimatedTime: null,
          definedTime: null,
          fallbackTime: null,
        };

        if (
          driverSegmentsCopy[index + 1] &&
          dayjs(waypoint.waypointStart.time).isSame(driverSegmentsCopy[index + 1].waypointStart.time, 'minute') &&
          waypoint.waypointStart.place.id === driverSegmentsCopy[index + 1].waypointStart.place.id
        ) {
          waypoint.locomotives.push(...driverSegmentsCopy[index + 1].locomotives);
          waypoint.employees.push(...driverSegmentsCopy[index + 1].employees);
          this.dependantWaypoints[waypoint.id] = [
            ...(this.dependantWaypoints[waypoint.id] ?? []),
            driverSegmentsCopy[index + 1].id,
          ];
          driverSegmentsCopy.splice(index + 1, 1);
        }
      });

      driverSegmentsCopy.forEach((waypoint, index) => {
        // eslint-disable-next-line no-param-reassign
        waypoint.position = index;
        // eslint-disable-next-line no-param-reassign
        delete waypoint['@id'];
      });

      const getSiblingWaypointsWithCalculatedDistance = (index) => {
        const route = [driverSegmentsCopy[index]];

        if (driverSegmentsCopy[index].waypointStart.place === null) {
          return [];
        }

        if (driverSegmentsCopy[index + 1]?.waypointStart?.place) {
          route.push(driverSegmentsCopy[index + 1]);
        }

        return route;
      };

      const calculateKilometerWaypointDistanceWithoutTime = async ({ index }) => {
        const route = getSiblingWaypointsWithCalculatedDistance(index);

        if (route.length >= 2) {
          const data = await TaxiCourseService.calculateKilometerRoute({ segments: route });

          data.forEach((item) => {
            Vue.set(driverSegmentsCopy, item.position, { ...item, distance: item.distance ?? 0, time: item.time ?? 0 });
          });
        }
      };

      const processSequentially = async () => {
        for (let index = 0; index < driverSegmentsCopy.length; index += 1) {
          // eslint-disable-next-line no-await-in-loop
          await calculateKilometerWaypointDistanceWithoutTime({ index });
        }
      };

      await processSequentially();

      return driverSegmentsCopy;
    },
    async getEditedRidePayload() {
      // TODO: add two kinds of starting base to the payload
      const startingBase = await this.startingBaseStrategy.payload({
        course: this.course,
        ride: this.ride,
        selectedStartingBase: this.selectedStartingBase,
        selectedStartingBaseChanged: this.selectedStartingBaseChanged,
      });

      const startingBasePayload = startingBase?.rides
        ? startingBase.rides[0]
        : { type: this.isKilometerCourseRide ? 'KilometerCourseRide' : 'taxiCourseRide', id: this.ride['@id'] };

      const segment = await this.segmentsEditStrategy.payload(this.waypointsForEdit);

      return {
        ...segment,
        ...startingBasePayload,
      };
    },
    getPlaceName(segment) {
      return segment?.place?.name;
    },
    getPlaceAddress(segment) {
      return segment?.place?.address;
    },
    confirmDialog(confirm) {
      if (confirm) {
        this.$emit('segmentAchieved', this.selectedWaypoint);
      }

      this.selectedWaypoint = false;
    },
    getPlaceIcon(place) {
      // TODO: refactor with mixin
      // ? consider different default icon for segment-timeline
      switch (place?.['@type']) {
        case 'Hotel':
          return 'mdi-bed';
        case 'Home':
          return 'mdi-home';
        case 'Workplace':
          return 'mdi-train';
        case 'Place':
          return 'pin_drop';
        default:
          return 'mdi-circle-medium';
      }
    },
    getEmployees(segment) {
      return this.isIntermediateWaypointsVisible ? segment.employees : this.getEmployeesFromAllSegments();
    },
    getEmployeesFromAllSegments() {
      return uniqueValues(this.segmentsWithAllWaypoints, 'employees');
    },
    distanceLabel(segment) {
      return this.isIntermediateWaypointsVisible ? segment.distance : this.totalSegmentsDistance;
    },
    durationLabel(segment) {
      return this.isIntermediateWaypointsVisible ? segment.time : this.totalRideDuration;
    },
    locomotivesLabel(segment) {
      return this.isIntermediateWaypointsVisible ? this.segmentLocomotives(segment) : this.locomotivesFromRide;
    },
    segmentLocomotives(segment) {
      return segment.locomotives.map((locomotive) => locomotive.sideNumber).join(',');
    },
    emitAcceptRide() {
      this.$emit('acceptRide', TAXI_COURSE_RIDE_STATUS.ACCEPTED_BY_TRANSPORT_COMPANY);
    },
    emitDeclineRide() {
      this.$emit('declineRide', TAXI_COURSE_RIDE_STATUS.DECLINED_BY_TRANSPORT_COMPANY);
    },
    openRideDeclineDialog() {
      this.showRideDeclineDialog = true;
    },
    declineRideConfirmDialog(isConfirmed) {
      if (isConfirmed) {
        this.emitDeclineRide();
      }
      this.showRideDeclineDialog = false;
    },
    openRideEditDialog() {
      this.rideEditDialog = true;
      // TODO: refactor -- probably violation of SRP
      this.setCourseForEdition();
    },
    openStartingBaseEditDialog() {
      this.startingBaseEditDialog = true;
      this.setCourseForEdition();
    },
    setCourseForEdition() {
      if (this.ride.segments.length === 0) {
        this.$store.commit('courseCreation/SET_COURSE', {
          rides: [
            {
              segments: [
                {
                  ...this.ride.fromStartingBase.waypointEnd,
                  waypointStart: {
                    place: this.ride.fromStartingBase.waypointEnd.place,
                    time: this.ride.fromStartingBase.waypointEnd.time.split('+')[0],
                  },
                  locomotives: this.ride.fromStartingBase.locomotives,
                  employees: this.ride.fromStartingBase.employees,
                },
              ],
              company: this.getCompanies,
            },
          ],
        });
      } else {
        this.$store.commit('courseCreation/SET_COURSE', {
          rides: [
            { company: this.getCompanies, segments: new TaxiCourseAdapter(this.ride).transformSegmentsToWaypoints() },
          ],
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.segments-timeline {
  &__link {
    color: rgba(0, 0, 0, 0.6);
  }

  &__avatar {
    font-size: 11px;
    font-weight: 600;
  }

  &__employee-name {
    display: inline-flex;
    align-items: center;
    column-gap: 4px;

    &:not(:last-of-type) {
      padding-right: 10px;
    }
  }
}

hr {
  border-left: 1px solid rgba(0, 0, 0, 0.12);
}

.col-gap-4 {
  column-gap: 16px;
}

.success-segment {
  .v-chip {
    color: #3b873e;
    background: rgba(123, 198, 126, 0.5) !important;
  }

  .text-body-1 {
    color: #3b873e !important;
    font-weight: 500;
  }

  .v-icon {
    color: #4caf50;
  }
}
</style>
