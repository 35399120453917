import Vue from 'vue';
import defaultApiResultManager from '@/core/shared/defaultApiResultManager';

function createFilters(filters) {
  const params = new URLSearchParams();

  Object.keys(filters).forEach((key) => {
    if (filters[key]) {
      params.append(key, filters[key]);
    }
  });

  params.append('pagination', true);

  return params.toString();
}

export default {
  fetchUsers(filters) {
    return Vue.http.get(`api/users?${createFilters(filters)}`);
  },
  updateUser({ userId, payload }) {
    return Vue.http.put(`api/users/${userId}`, payload).catch(({ data, status }) => {
      Vue.notify(defaultApiResultManager.catch(data, status));
      throw new Error(data['hydra:description']);
    });
  },
  requestResetPassword(payload) {
    return Vue.http.post('api/users/reset-password-request', { identifier: payload }).catch(({ data, status }) => {
      Vue.notify(defaultApiResultManager.catch(data, status, data.error));
      throw new Error(data.error);
    });
  },
  resetPassword({ token, password }) {
    return Vue.http
      .post(`api/users/reset-password?token=${token}`, { password })
      .then(({ data }) => {
        Vue.notify(defaultApiResultManager.then(data));
      })
      .catch(({ data, status }) => {
        Vue.notify(defaultApiResultManager.catch(data, status, data.error));
        throw new Error(data.error);
      });
  },
};
