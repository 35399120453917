<template>
  <v-autocomplete
    prepend-inner-icon="mdi-account"
    :value="value"
    :items="users"
    :loading="isUsersLoading"
    :search-input.sync="search"
    hide-no-data
    label="Dodane przez"
    return-object
    outlined
    item-text="name"
    v-on="$listeners"
  >
    <template v-slot:append-item>
      <div v-intersect="endIntersect"></div>
    </template>
  </v-autocomplete>
</template>

<script>
import { mapGetters } from 'vuex';
import { debounce } from 'lodash';
import { FILTER_DEBOUNCE_TIME, DEFAULT_ITEMS_PER_PAGE, MIN_SEARCH_PHRASE_LENGTH } from '@/core/dict/search-field-dict';

export default {
  name: 'CreatedByField',
  inheritAttrs: false,
  props: {
    value: {
      required: true,
    },
  },
  data() {
    return {
      search: null,
      pageLoaded: 1,
    };
  },
  created() {
    if (this.isInputFilledWithValue) {
      this.$store.dispatch('users/fetchUsersWithSelectedUser', this.value.id);
    } else {
      this.$store.dispatch('users/fetchUsersForInput');
    }
  },
  computed: {
    ...mapGetters('users', ['users', 'isUsersLoading', 'totalUsersCount']),
    isInputFilledWithValue() {
      return this.value !== null;
    },
    isMoreItemsAvailable() {
      return Math.ceil(this.totalUsersCount / DEFAULT_ITEMS_PER_PAGE) > this.pageLoaded;
    },
    isSearchEqualsToSelectedItem() {
      return this.search === this.value?.name;
    },
  },
  methods: {
    fetchFilteredUsers: debounce(function () {
      this.fetchFilteredUsersCallback();
    }, FILTER_DEBOUNCE_TIME),
    fetchFilteredUsersCallback() {
      this.$store.dispatch('users/fetchUsersForInput', { search: this.search });
    },
    endIntersect(entries, observer, isIntersecting) {
      if (isIntersecting && this.isMoreItemsAvailable) {
        this.pageLoaded += 1;
        this.$store.dispatch('users/fetchUsersForInput', {
          page: this.pageLoaded,
          search: this.isSearchEqualsToSelectedItem ? '' : this.search,
        });
      }
    },
  },
  watch: {
    search(val) {
      if (val && val.length >= MIN_SEARCH_PHRASE_LENGTH && !this.isSearchEqualsToSelectedItem) {
        this.fetchFilteredUsers();
        this.pageLoaded = 1;
      } else if (val === '') {
        this.pageLoaded = 1;
        this.$store.dispatch('users/fetchUsersForInput');
      }
    },
  },
};
</script>
