import UserService from '@/service/UserService';
import shared from '@/core/shared';
import { DEFAULT_ITEMS_PER_PAGE } from '@/core/dict/search-field-dict';
import * as types from './types/user-mutation-types';

export default {
  namespaced: true,
  state: {
    users: [],
    isUsersLoading: false,
    totalUsersCount: 0,
  },
  getters: {
    users: (state) => state.users,
    isUsersLoading: (state) => state.isUsersLoading,
    totalUsersCount: (state) => state.totalUsersCount,
  },
  mutations: {
    [types.SET_USERS]: (state, val) => {
      state.users = val;
    },
    [types.ADD_USERS]: (state, val) => {
      state.users = [...state.users, ...val];
    },
    [types.SET_USERS_LOADING]: (state, val) => {
      state.isUsersLoading = val;
    },
    [types.SET_USERS_TOTAL_COUNT]: (state, val) => {
      state.totalUsersCount = val;
    },
  },
  actions: {
    fetchUsers({ commit }, filters) {
      commit(types.SET_USERS_LOADING, true);
      return UserService.fetchUsers(filters)
        .then(({ data }) => {
          commit(types.SET_USERS_TOTAL_COUNT, data['hydra:totalItems']);
          commit(
            types.SET_USERS,
            data['hydra:member'].map((user) => ({ ...user, accountType: shared.getAccountType(user) }))
          );
        })
        .finally(() => {
          commit(types.SET_USERS_LOADING, false);
        });
    },
    fetchUsersWithSelectedUser({ commit }, id) {
      commit(types.SET_USERS_LOADING, true);
      return Promise.all([
        UserService.fetchUsers({ itemsPerPage: DEFAULT_ITEMS_PER_PAGE }),
        UserService.fetchUsers({ itemsPerPage: DEFAULT_ITEMS_PER_PAGE, id }),
      ])
        .then((responses) => {
          const [{ data: users }, { data: selectedUser }] = responses;
          commit(types.SET_USERS, [...users['hydra:member'], ...selectedUser['hydra:member']]);
          commit(types.SET_USERS_TOTAL_COUNT, users['hydra:totalItems']);
        })
        .finally(() => {
          commit(types.SET_USERS_LOADING, false);
        });
    },
    fetchUsersForInput({ commit }, filters = {}) {
      commit(types.SET_USERS_LOADING, true);
      return UserService.fetchUsers({ ...filters, itemsPerPage: DEFAULT_ITEMS_PER_PAGE })
        .then(({ data }) => {
          commit(types.SET_USERS_TOTAL_COUNT, data['hydra:totalItems']);
          if ('page' in filters) {
            commit(types.ADD_USERS, data['hydra:member']);
          } else {
            commit(types.SET_USERS, data['hydra:member']);
          }
        })
        .finally(() => {
          commit(types.SET_USERS_LOADING, false);
        });
    },
  },
};
