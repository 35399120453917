// eslint-disable-next-line import/prefer-default-export
export class PassengerMapper {
  static toDTO({ entity }) {
    // TODO: remove .user nesting form passenger factory
    return {
      user: {
        firstname: entity.user.firstname,
        surname: entity.user.surname,
        username: entity.user.username,
        email: entity.user.email,
        home: entity.user.home ? entity.user.home['@id'] : null,
        phones: entity.user.phones.map((val) => ({ phone: val.phone, main: val.main })).filter((val) => val.phone),
        company: entity.user.company['@id'],
        id: entity.user['@id'],
        employeeGroup: entity.user.employeeGroup ? entity.user.employeeGroup['@id'] : null,
        cars: entity.user.car ? [{ id: entity.user.car.id }] : [],
      },
    };
  }
}
